<template>
  <div v-if="content">
    <template
      v-for="(item, index) in content"
      :key="index"
    >
      <div
        v-if="item._type !== 'table'"
        class="prose prose-invert w-full max-w-none break-words mt-3"
        v-html="$sanityBlocksToHtml(item)"
      />
      <CmsTable v-if="item._type === 'table'" :rows="item.rows"/>
    </template>
  </div>
</template>

<script setup>
const props = defineProps({
  query: {
    type: String,
    default: '',
  },
  preLoadedContent: {
    type: Object,
    default: () => ({}),
  },
});

const emits = defineEmits([
  'data-loaded',
]);

const { $sanity, $rollbar, $sanityBlocksToHtml, } = useNuxtApp();

const content = ref(null);

await fetchData();

async function fetchData() {
  if (Object.keys(props.preLoadedContent).length > 0) {
    content.value = props.preLoadedContent.content;
    emits('data-loaded', content.value);
    return;
  }

  try {
    const res = await $sanity.fetch(props.query);
    emits('data-loaded', res);
    if (res?.content) {
      content.value = res.content;
    }
  } catch (err) {
    $rollbar.error('fetchData::', err);
    emits('data-loaded', null);
  }
}

watch(
  () => props.query,
  (newVal) => {
    if (newVal) { fetchData(); }
  }
);
</script>
